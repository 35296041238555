import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./psmbottomcarousel.css";

const API_BASE_URL = "https://api.psmmag.com";
const UNDER_HEADLINE_COMPONENTS_URL = `${API_BASE_URL}/under-headline-components?limit=10`; // Son 20 haberi çekmek için limit parametresi

const PsmBottomCarousel = () => {
  const [newsItems, setNewsItems] = useState([]);
  const [startIndex, setStartIndex] = useState(0);
  const carouselRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await axios.get(UNDER_HEADLINE_COMPONENTS_URL);
        const newsData = Array.isArray(response.data)
          ? response.data
          : response.data.news;

          // console.log("bottom carousel:", response.data); 

        if (Array.isArray(newsData)) {
          const highlightedNews = newsData.filter((item) => !item.isDraft);
          setNewsItems(highlightedNews);
        } else {
          console.error("API yanıtı bir dizi değil:", response.data);
        }
      } catch (error) {
        console.error("Error fetching news:", error);
      }
    };

    fetchNews();
  }, []);

  const handleNext = () => {
    setStartIndex((prevIndex) => (prevIndex < 7 ? prevIndex + 1 : 0));
  };

  const handlePrev = () => {
    setStartIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : newsItems.length - 1
    );
  };

  useEffect(() => {
    const carousel = carouselRef.current;

    if (carousel) {
      const itemWidth = carousel.clientWidth / 3;
      carousel.style.transition = "transform 0.5s ease";
      carousel.style.transform = `translateX(-${startIndex * itemWidth}px)`;
    }
  }, [startIndex, newsItems.length]);

  useEffect(() => {
    const interval = setInterval(() => {
      handleNext();
    }, 5000);

    return () => clearInterval(interval);
  }, [startIndex, newsItems.length]);

  const handleNewsClick = async (newsId, slug, event) => {
    event.preventDefault();
    try {
    
      await axios.put(`${API_BASE_URL}/${newsId}/increment-view`);

    
      navigate(`/${slug}`);
    } catch (error) {
      console.error("Görüntülenme sayısı artırılırken hata oluştu:", error);
      
      navigate(`/${slug}`);
    }
  };

  return (
    <div className="carousel psm-bottom-carousel">
      <button className="psm-bottom-carousel-button" onClick={handlePrev}>
      <span class="material-symbols-outlined pt-2">
chevron_left
</span>
      </button>
      <div className="psm-bottom-carousel-inner-wrapper">
        <div className="psm-bottom-carousel-inner" ref={carouselRef}>
          {newsItems.map((item, index) => (
            <div
              className="carousel-item psm-bottom-carousel-item cursor-pointer"
              style={{ backgroundImage: `url(${item.image})` }}
              key={index}
              onClick={(e) => handleNewsClick(item._id, item.slug, e)}
            >
              <h3 className="carousel-item-title">{item.title}</h3>
            </div>
          ))}
        </div>
      </div>
      <button className="psm-bottom-carousel-button" onClick={handleNext}>
      <span class="material-symbols-outlined pt-2">
chevron_right
</span>
      </button>
    </div>
  );
};

export default PsmBottomCarousel;
