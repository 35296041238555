import React from 'react';
import "./policyPage.css"
import TitleBar from '../../components/titleBar/TitleBar';

const PersonalData = () => {
    return (
        <div className='policy-page-container'>



            <div className="text-center bg-[#C90913] p-2 mb-4 shadow-md text-white">
                <h1 className="text-2xl font-bold " >Kişisel Verilerin İşlenmesine İlişkin Aydınlatma Metni</h1>
            </div>


            <div class="policy-page-section">

                <p>Erişim Medya Radyo Televizyon ve Dergi Yayıncılık A.Ş. (Mersis No:2152176255933922) (“PSM”) ya da (“Şirket”) olarak, 6698 Sayılı Kişisel Verilerin Korunması Kanunu’nun (“KVKK”) 10. maddesi uyarınca; gerçekleştirdiğimiz kişisel veri işleme faaliyetleri hakkında sizleri bilgilendirmek ve aydınlatmak isteriz.</p>

            </div>

            <div class="policy-page-section">
                <h2>1.	Kişisel Veri, Veri İşleyen, Veri Kayıt Sistemi</h2>
                <p>Kimliği belirli veya belirlenebilir gerçek kişiye ilişkin, 6698 sayılı Kişisel Verileri Koruma Kanununda tanımlanan her türlü bilgi kişisel veri olarak kabul edilir. Veri İşleyen; Veri sorumlusunun verdiği yetkiye dayanarak onun adına kişisel verileri işleyen gerçek veya tüzel kişiyi. Veri kayıt sistemi; kişisel verilerin belirli kriterlere göre yapılandırılarak işlendiği kayıt sistemini ifade etmektedir.</p>
            </div>

            <div class="policy-page-section">
                <h2>2.	Kişisel Veri Sorumlusu ve Temsilcisi</h2>
                <p>Tarafınıza ait kişisel veriler, PSM tarafından veri sorumlusu sıfatıyla aşağıda açıklanan amaçlar doğrultusunda ve bunlarla sınırlı olarak, hukuka ve dürüstlük kurallarına uygun suretle işlenebilecek, kaydedebilecek, saklanabilecek, sınıflandırılabilecek, güncellenebilecek ve mevzuatın izin verdiği hallerde veyahut işlendikleri amaçla sınırlı olarak üçüncü kişilere açıklanabilecek ve aktarabilecektir. Veri Sorumlusu; 6698 Sayılı KVKK’nun 10.1 Maddesi “Veri Sorumlusunun Aydınlatma Yükümlülüğü” başlıklı (a) bendi veri sorumlusunun kimliği konusunda bilgi verilmesi yükümlülüğünü, keza aynı kanunun 3.1.ı maddesi ise veri sorumlusu: “Kişisel verilerin işleme amaçlarını ve vasıtalarını belirleyen, veri kayıt sisteminin kurulmasından ve yönetilmesinden sorumlu olan gerçek veya tüzel kişiler” olarak tanımlanmıştır. Kanunun mezkur uygulaması bakımından Veri Sorumlusu; yasal mevzuata uygun şekilde ticari, ekonomik, sosyal ve kültürel faaliyet sürdüren Erişim Medya Radyo Televizyon ve Dergi Yayıncılık A.Ş.’dir. </p>
            </div>

            <div class="policy-page-section">
                <h2>3.	İşlenen Kişisel Verilerin Tanımı</h2>
                <p>Tarafınıza ait kişisel veriler, PSM’nin haber, ürün, hizmet, etkinlik, organizasyon ve tanıtımlarından yararlanmaktayken bizlerle paylaşmış olduğunuz adınız, soyadınız, unvanınız, işyeriniz, adresiniz ile elektronik posta adresi gibi iletişim bilgileriniz, katıldığınız etkinliğin adı, etkinlik sırasında toplanan veriler ile çekilen fotoğraf ve videolar ve bunlarla sınırlı olmamak kaydıyla KVKK’nunda tanımlanan kişisel veri tanımına giren, Erişim Medya Radyo Televizyon ve Dergi Yayıncılık A.Ş.’nin söz konusu faaliyet ve etkinliği gerçekleştirmesi için ihtiyacı olan her türlü veriden oluşmaktadır.</p>
            </div>

            <div class="policy-page-section">
                <h2>4.	Kişisel Verilerinizin İşlenme Amacı</h2>

                <p>Kişisel verilerinizi, 6698 Sayılı KVKK’nun 4, 5 ve 6.maddelerinde belirtilen kişisel veri işleme ilkelerine uygun olarak; Erişim Medya Radyo Televizyon ve Dergi Yayıncılık A.Ş.’nin hak sahibi olduğu web sitesi ve/veya mobil uygulamalar üzerinden haber, ürün, hizmet, etkinlik, organizasyon ve tanıtımlarından yararlanmakta olan üye, katılımcı veya ziyaretçinin kimlik bilgilerini teyit etmek; iletişim için adres ve diğer gerekli bilgileri kaydetmek; bilgilerinizi çözüm ortaklarımıza iletmek; üye, katılımcı ve ziyaretçilerimizle iletişime geçmek, gerekli bilgilendirmeleri yapabilmek, haber içerikleri, etkinlik ve organizasyonları aktarmak; elektronik (internet/mobil vs.) veya kağıt ortamında işleme dayanak olacak tüm kayıt ve belgeleri düzenlemek; kamu güvenliğine ilişkin hususlarda talep halinde ve mevzuat gereği kamu görevlilerine bilgi verebilmek; üyelerimizi, katılımcılarımızı ve ziyaretçilerimizi tanıyabilmek, memnuniyetini arttırabilmek ve amacımızla ilintili çeşitli tanıtım, reklam faaliyetlerinde kullanabilmek ve elektronik ortamda ve/veya fiziki ortamda anketler düzenlemek; anlaşmalı kurumlarımız ve çözüm ortaklarımız tarafından üyelerimize öneri sunabilmek, hizmetlerimizle ilgili üyelerimizi, katılımcılarımızı ve ziyaretçilerimizi bilgilendirebilmek, üye, katılımcı ve ziyaretçi şikayet ve önerilerini değerlendirebilmek; yasal yükümlülüklerimizi yerine getirebilmek ve yürürlükteki mevzuattan doğan haklarımızı kullanabilmek; dolandırıcılık ve diğer yasa dışı faaliyetlerin önüne geçebilmek amaçlarıyla tüm faaliyetlerimizi yürütmek çerçevesinde, katılımcıların belirlenmesi, katılımcı kitlesinin analizi, daha sonraki etkinlikler için fikir yürütülmesi, yaka kartı ya da katılımcı listelerinin oluşturulması amacıyla işlemekteyiz.</p>

            </div>

            <div class="policy-page-section">
                <h2>5.	Kişisel Verilerinizi Kimlere ve Hangi Amaçla Aktarıyoruz</h2>

                <p>Bizlerle paylaşmış olduğunuz kişisel verileriniz 6698 sayılı Kişisel Verilerin Korunması Kanunu kapsamında kalmak kaydıyla; ticari faaliyetimiz gereklilik ölçütlerinde ve keza düzenlenen etkinlik ve organizasyon süresince genel güvenliğin ve düzenin sağlanması amacıyla etkinlik alanı sahibi, etkinlik iş birliği ortakları veya sponsorları gibi üçüncü kişilerle paylaşılabilmektedir. Ayrıca, etkinlik kapsamında elde edilen fotoğraf, video gibi kişisel verileriniz etkinliğin veya kurumun tanıtımı, bilgilendirilmesi ve reklam faaliyetlerinin yürütülmesi amacı ile sosyal medya hesapları (Linkedin, Facebook, Twitter, Instagram, Youtube, Google gibi) aracılığı ile paylaşılabilmektedir. Kişisel verileriniz Şirketimiz ve şirketimizle ilişki içerisinde olan kişilerin hukuki ve ticari güvenliğinin temini ve şirket stratejilerinin belirlenmesi ve uygulanması amaçlarıyla iş ortaklarımıza, tedarikçilerimize, kanunen yetkili kamu kurumlarına ve özel kişilere, KVKK’nun 8. ve 9. maddelerinde belirtilen kişisel veri işleme şartları ve amaçları çerçevesinde aktarılabilecektir.</p>


            </div>

            <div class="policy-page-section">
                <h2>6.	Kişisel Verilerinizin Toplanma Yöntemi ve Hukuki Dayanağı</h2>

                <p>Kişisel Verileriniz, Ticari faaliyetlerimizi yürütmek ve sağlıklı iletişimin sağlanması amacıyla Erişim Medya Radyo Televizyon ve Dergi Yayıncılık A.Ş. tarafından “Etkinlik Kayıt Formu” ile internet sitesi veya e-posta aracılığıyla elektronik olarak ya da telefon aracılıyla sözel olarak toplanmaktadır. Kişisel Verileriniz; 6698 sayılı Kişisel Verilerin Korunması Kanunu, 6563 Sayılı Elektronik Ticaretin Düzenlenmesi Hakkında Kanun, 6102 sayılı Türk Ticaret Kanunu, 6502 sayılı Tüketicinin Korunması Hakkında Kanun, 5237 Sayılı Türk Ceza Kanunu, Mesafeli Sözleşmeler Yönetmeliği’nden ve ilgili yasal mevzuat gereği doğan yükümlülüklerimizin ifası amacıyla toplanmakta, işlenmekte, açıklanmakta ve aktarılmaktadır.</p>


            </div>

            <div class="policy-page-section">
                <h2>7.	Kişisel Verilerinizin Korunması ve Süresi</h2>

                <p>PSM olarak, Kişisel Verilerinizi yetkisiz erişime, kullanıma ve açıklanmaya karşı korumak için çeşitli güvenlik önlemleri uygulamaktayız. Bunları erişim kontrolleri, parolalar, düzenli güvenlik değerlendirmeleri gibi uygun güvenlik standartları olarak genel kabul görmüş teknik ve kurumsal önlemler takip etmektedir. Kişisel verilerinizi, daha kısa veya daha uzun bir saklama süresi gerekmedikçe veya geçerli yasa kapsamında izin verildiği ölçüde, bu beyanda belirtilen amaçları yerine getirmek için gerekli olduğu müddetçe saklamaktayız. Buna göre başta yasal zamanaşımı süreleri olmak üzere, kanunda öngörülen süreleri ve verinin işlenmesi için gereken sürelerde saklamaktayız.</p>

            </div>

            <div class="policy-page-section">
                <h2>8.	Kişisel Veri Sahibinin Kanuni Hakları</h2>

                <p>Kişisel veri sahibi olarak, haklarınıza ilişkin taleplerinizi psm@psmmag.com  adresine e-posta göndererek tarafımıza iletebilirsiniz. PSM, talebin niteliğine göre en kısa sürede ve en geç 30 gün içinde sonuçlandıracaktır. İşlemin ayrıca bir maliyeti gerektirmesi hâlinde, tarafımızca Kişisel Verileri Koruma Kurulunca belirlenen tarifedeki ücret talep edilecektir. Kişisel Veri sahibi gerçek kişilerin Kişisel Verilerin Korunması Kanunu’nun 11.Maddesi uyarınca sahip olduğu haklar aşağıdaki gibidir: Kişisel veri işlenip işlenmediğini öğrenme, Kişisel verileri işlenmişse buna ilişkin bilgi talep etme, Kişisel verilerin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme, Yurt içinde veya yurt dışında kişisel verilerin aktarıldığı üçüncü kişileri bilme, Kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme ve bu kapsamda yapılan işlemin kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme, KVKK ve ilgili diğer kanun hükümlerine uygun olarak işlenmiş olmasına rağmen, işlenmesini gerektiren sebeplerin ortadan kalkması hâlinde kişisel verilerin silinmesini veya yok edilmesini isteme ve bu kapsamda yapılan işlemin kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme, İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle kişinin kendisi aleyhine bir sonucun ortaya çıkmasına itiraz etme, Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması hâlinde zararın giderilmesini talep etme.</p>


            </div>

            <div class="policy-page-section">
                <h2>9.	Avrupa Birliğinde Yerleşik Kişisel Veri Sahiplerinin Hakları</h2>

                <p>Avrupa Birliği (“AB”) tarafından hazırlanarak yürürlüğe konulan Avrupa Birliği “Genel Veri Koruma Tüzüğü” (“GDPR”) “Genişletilmiş Etki Alanı” kuralını ilke olarak benimsemiş ve bu ilke gereğince Avrupa Birliği’nde yerleşik olmasa dahi Avrupa Birliği ülkelerine bağlı kişilere mal veya hizmet sunan veri sorumlularının GDPR kapsamında olduğunu belirtmiştir. Bu nedenle “PSM” tarafından kişisel verileri işlenen Avrupa Birliği’ne bağlı gerçek kişilerin kişisel verileri, KVKK’nun yanı sıra GDPR kapsamında da korunmaktadır. GDPR kapsamında korunan veri sahipleri, KVKK tarafından kendisine tanınan haklara ek olarak aşağıda belirtilen haklara da sahip olup bu doğrultuda da PSM’ye yönelik aşağıda yazılı hak ve taleplerde bulunabilirler: Veri sahibinin PSM’nin işlemekte olduğu kişisel verileri silmesini, belirli istisnalara tabi olarak işlemesini veya işlemeyi durdurmasını isteme, PSM’nin verileri pazarlama amacıyla kullanmayı durdurmasını isteme, PSM tarafından işlenmekte olan kişisel verilerin kendisine gönderilmesini veya başka bir veri sorumlusuna iletilmesini isteme, Herhangi bir sebeple PSM tarafından veri sahibinin kişisel verilerine erişiminin engellenmesi durumunda, erişimin engellenme sebebine yönelik bilgi alma. Kişisel Veri sahibinin söz konusu taleplerinin bir maliyet gerektirmesi halinde PSM, 6698 sayılı Kişisel Verilerin Korunması Kanunu’nun 13. Maddesine göre belirlenen tarife üzerinden ücret ve masraf talep etme hakkına sahiptir.</p>



            </div>

            <div class="policy-page-section">
                <h2>Kişisel Verilerinin İşlenmesine Yönelik Açık Rıza Beyanı
                </h2>

                <p>Şirket’in sahip olduğu PSM Awards, Smart-i Awards, WSpark, Gündem Toplantıları etkinlik katılımcılarına yönelik Kişisel Verilerin Korunması Aydınlatma Metni okudum. Bu kapsamda, kişisel verilerimin silinmesi, anonimleştirilmesi veya yok edilmesi için talepte bulunmadığım sürece veri işleme faaliyetlerine Şirket tarafından devam edilebileceğini, 6698 sayılı Kişisel Verilerin Korunması Kanunu’nun (“KVKK”) ilgili hükümlerine uygun olarak bilgime sunulan PSM Etkinlik Katılımcılarına Yönelik Kişisel Verilerin Korunması Aydınlatma Metni sınırları çerçevesinde iletişim bilgilerimin,
                    Etkinlik sonrası anket ve değerlendirme çalışmaları ile katıldığım etkinliğe ait olan ve şirketin sahibi olduğu (www.psmmag.com – www.sigortagundem.com – www.ekonomimanset.com) web sitelerindeki haber içeriklerinin ileti ve duyuruların gönderilmesi amacıyla işlenmesine,
                    İlgi alanımda olan daha sonra düzenlenecek etkinliklerden haberdar edilebilmem için işlenmesine, onay veriyorum.
                </p>

            </div>

            <br />
           



        </div>
    )
}

export default PersonalData