import React, { useState, useEffect } from "react";
import "./adsmid.css";

const AdContainer = ({ ads, currentAdIndex, handleAdClick }) => {
  const adRef = React.useRef(null);

  React.useEffect(() => {
    if (ads.length > 0) {
      const currentAd = ads[currentAdIndex];
      adRef.current.innerHTML = ''; // Önceki içeriği temizle

      if (currentAd.imageURL.includes('doubleclick.net')) {
        const iframe = document.createElement('iframe');
        iframe.src = currentAd.imageURL;
        iframe.width = '300';
        iframe.height = '300';
        iframe.frameBorder = '0';
        iframe.scrolling = 'no';
        iframe.style = 'border: 0px; vertical-align: bottom;';
        adRef.current.appendChild(iframe);
      } else if (currentAd.imageURL.includes('sadbundle')) {
        const iframe = document.createElement('iframe');
        iframe.src = currentAd.imageURL;
        iframe.width = '300';
        iframe.height = '300';
        iframe.frameBorder = '0';
        iframe.scrolling = 'no';
        iframe.allowFullscreen = true;
        adRef.current.appendChild(iframe);
      } else {
        const img = document.createElement('img');
        img.src = currentAd.imageURL;
        img.alt = currentAd.title;
        img.style = 'cursor: pointer; width: 100%; height: 100%;';
        img.addEventListener('click', () => handleAdClick(currentAd));
        adRef.current.appendChild(img);
      }
    }
  }, [ads, currentAdIndex, handleAdClick]);

  return (
    <div className="advertisement" style={{ width: "300px", height: "300px" }} ref={adRef}>
      {/* Reklam içeriği buraya dinamik olarak eklenecek */}
    </div>
  );
};

const AdsMidTop = () => {
  const [ads, setAds] = useState([]);
  const [currentAdIndices, setCurrentAdIndices] = useState([0, 0, 0]);

  useEffect(() => {
    const fetchAds = async () => {
      try {
        const response = await fetch("https://api.psmmag.com/api/ads");
        const data = await response.json();

        const filteredAds = data.filter(
          (ad) =>
            (ad.title.includes("Manşet Altı KARE Banner - SOL") ||
            ad.title.includes("Manşet Altı KARE Banner - ORTA") ||
            ad.title.includes("Manşet Altı KARE Banner - SAĞ")) &&
            ad.isActive === true
        );

        setAds(filteredAds);
        // console.log("Filtered ads:", filteredAds);
      } catch (error) {
        console.error("Reklamlar çekilemedi:", error);
      }
    };

    fetchAds();
  }, []);

  const handleAdClick = async (ad) => {
    try {
      await fetch(`https://api.psmmag.com/api/ads/${ad._id}/hit`, {
        method: "POST",
      });
      window.open(ad.link, '_blank');
    } catch (error) {
      console.error("Error incrementing hit count:", error);
    }
  };

  return (
    <div className="mid-top-ads-container">
      {[0, 1, 2].map((index) => (
        <AdContainer
          key={index}
          ads={ads.filter(ad => ad.title.includes(["SOL", "ORTA", "SAĞ"][index]))}
          currentAdIndex={currentAdIndices[index]}
          handleAdClick={handleAdClick}
        />
      ))}
    </div>
  );
};

export default AdsMidTop;