import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./psmcarousel.css";
import axios from "axios";
import SpecialNewsCard from "./SpecialNewsCard";
import PsmBottomCarousel from "./PsmBottomCarousel";
import PsmBottomCarouselMobile from "./PsmBottomCarouselMobile";
import PsmBottomCarouselMiddle from "./PsmBottomCarouselMiddle";
import LiveStream from "../liveStream/LiveStream";

const API_BASE_URL = "https://api.psmmag.com";

const PsmCarousel = () => {
  const [topCarouselNews, setTopCarouselNews] = useState([]);
  const [pinnedNews, setPinnedNews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const [newsResponse, pinnedResponse] = await Promise.all([
          axios.get(`${API_BASE_URL}/full-width-headlines`),
          axios.get(`${API_BASE_URL}/sabitlenmis`),
        ]);
  
        console.log("Haber Verileri:", newsResponse.data); 
        console.log("Sabitlenmiş Haber Verileri:", pinnedResponse.data); 
  
        let fetchedNews = [];
        if (Array.isArray(newsResponse.data.news)) {
          fetchedNews = newsResponse.data.news.filter(
            (news) => !news.isDraft
          );
        } else {
          console.error("API'den beklenen veri formatı bir dizi değil.");
        }
  
        if (Array.isArray(pinnedResponse.data)) {
          setPinnedNews(pinnedResponse.data); 
        }
  
        const combinedSlides = Array(15).fill(null); 
        const usedPositions = new Set();
        const addedNews = new Set(); 
  
       
        pinnedResponse.data.forEach((news) => {
          if (
            news.sabitlenmisSlidePosition !== null &&
            news.sabitlenmisSlidePosition >= 1 &&
            news.sabitlenmisSlidePosition <= 15
          ) {
            const position = news.sabitlenmisSlidePosition - 1;
            combinedSlides[position] = news;
            usedPositions.add(position);
            addedNews.add(news._id); 
          }
        });
  
        let i = 0;
  
        
        fetchedNews.forEach((news) => {
          if (addedNews.has(news._id)) return; 
          
          
          while (i < combinedSlides.length && (combinedSlides[i] !== null || usedPositions.has(i))) {
            i++;
          }
  
         
          if (i < combinedSlides.length) {
            combinedSlides[i] = news;
            addedNews.add(news._id); 
            i++; 
          }
        });
  
        // console.log("Carousel için Kombine Edilmiş Slide Verileri:", combinedSlides);
        setTopCarouselNews(combinedSlides); 
      } catch (error) {
        console.error("Veriler alınırken bir hata oluştu:", error);
      } finally {
        setLoading(false); 
      }
    };
  
    fetchNews();
  }, []);
  
  

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % topCarouselNews.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [topCarouselNews.length]);

  const goToPrevious = () => {
    setCurrentIndex(
      (prevIndex) =>
        (prevIndex - 1 + topCarouselNews.length) % topCarouselNews.length
    );
  };

  const goToNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % topCarouselNews.length);
  };

  const handleNewsClick = async (newsId, slug, event) => {
    event.preventDefault();
    try {
      await axios.put(`${API_BASE_URL}/${newsId}/increment-view`);
      navigate(`/${slug}`);
    } catch (error) {
      console.error("Error incrementing view count:", error);
      navigate(`/${slug}`);
    }
  };

  const getNewsTag = (slide) => {
    if (slide?.isFlashNewsTag) return "Flaş Haber";
    if (slide?.isBreakingNewsTag) return "Son Dakika";
    if (slide?.isExclusiveNewsTag) return "Özel Haber";
    if (slide?.isAttentionNewsTag) return "Bu Habere Dikkat";
    return null;
  };

  if (loading) {
    // return <div>Yükleniyor...</div>;
  }

  if (!topCarouselNews.length) {
    // return <div>Haber bulunamadı.</div>;
  }

  return (
    <div className="psm-carousel-container lg:mx-[550px]">
      <LiveStream />
      <div className="psm-carousel-top-container lg:grid grid-cols-3 gap-4 mb-4">
        <div className="psm-carousel-top-left-container col-span-2 relative overflow-hidden">
          <div
            className="psm-carousel-inner"
            style={{
              transform: `translateX(-${currentIndex * 100}%)`,
              display: "flex",
              transition: "transform 0.5s ease-in-out",
            }}
          >
            {topCarouselNews.map((slide, index) => (
              slide && (
                <div
                  key={slide._id}
                  onClick={(e) => handleNewsClick(slide._id, slide.slug, e)}
                  className={`psm-top-carousel-item cursor-pointer ${index === currentIndex ? "active" : ""}`}
                  style={{
                    backgroundImage: `url(${slide.image})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    flexShrink: "0",
                    width: "100%",
                    position: "relative",
                  }}
                >
                  <div className="psm-top-carousel-caption">
                    <h5>{slide.title}</h5>
                  </div>
                  {getNewsTag(slide) && (
                    <div
                      className="slide-title"
                      style={{
                        
                      }}
                    >
                      {getNewsTag(slide)}
                    </div>
                  )}
                </div>
              )
            ))}
          </div>
          <button className="carousel-control-prev" onClick={goToPrevious}>
            <span className="material-symbols-outlined">arrow_back_ios</span>
          </button>
          <button className="carousel-control-next" onClick={goToNext}>
            <span className="material-symbols-outlined">arrow_forward_ios</span>
          </button>
        </div>
        <div className="psm-carousel-top-right-container">
          <SpecialNewsCard />
        </div>
      </div>
      <div className="psm-carousel-bottom-container">
        <PsmBottomCarousel />
      </div>
      <div className="hidden psm-carousel-bottom-mobile-container">
        <PsmBottomCarouselMobile />
      </div>
      <div className="hidden psm-carousel-bottom-middle-container">
        <PsmBottomCarouselMiddle />
      </div>
    </div>
  );
};

export default PsmCarousel;
