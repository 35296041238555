import React, { useState, useEffect } from "react";
import "./dergiCarousel.css"; // Stil dosyanızı ekleyebilirsiniz
// import Agustos2024 from "../../pages/MagazinePage/issues/PSM-Agustos-2024.pdf";
// import Eylul2024 from "../../pages/MagazinePage/issues/PSM-Eylul-2024.pdf";
// import Temmuz2024 from "../../pages/MagazinePage/issues/PSM-Temmuz-2024.pdf";
// import Haziran2024 from "../../pages/MagazinePage/issues/PSM-Haziran-2024.pdf";
// import Mayıs2024 from "../../pages/MagazinePage/issues/PSM-Mayıs-2024.pdf";
// import Ocak2023 from "../../pages/MagazinePage/issues/PSM-Ocak-2023.pdf";
// import Subat2023 from "../../pages/MagazinePage/issues/PSM-Subat-2023.pdf";
// import Mart2023 from "../../pages/MagazinePage/issues/PSM-Mart-2023.pdf";
// import Nisan2023 from "../../pages/MagazinePage/issues/PSM-Nisan-2023.pdf";
// import Mayıs2023 from "../../pages/MagazinePage/issues/PSM-Mayıs-2023.pdf";
// import Haziran2023 from "../../pages/MagazinePage/issues/PSM-Haziran-2023.pdf";
// import Temmuz2023 from "../../pages/MagazinePage/issues/PSM-Temmuz-2023.pdf";
// import Agustos2023 from "../../pages/MagazinePage/issues/PSM-Agustos-2023.pdf";
// import Eylul2023 from "../../pages/MagazinePage/issues/PSM-Eylul-2023.pdf";
// import Ekim2023 from "../../pages/MagazinePage/issues/PSM-Ekim-2023.pdf";
// import Kasım2023 from "../../pages/MagazinePage/issues/PSM-Kasım-2023.pdf";
// import Aralık2023 from "../../pages/MagazinePage/issues/PSM-Aralık-2023.pdf";
// import Ocak2024 from "../../pages/MagazinePage/issues/PSM-Ocak-2024.pdf";
// import Subat2024 from "../../pages/MagazinePage/issues/PSM-Subat-2024.pdf";
// import Mart2024 from "../../pages/MagazinePage/issues/PSM-Mart-2024.pdf";
// import Nisan2024 from "../../pages/MagazinePage/issues/PSM-Nisan-2024.pdf";
// import Ekim2024 from "../../pages/MagazinePage/issues/PSM-Ekim-2024.pdf";
import Ekim2024Cover from "./PSM-Ekim-2024.jpg"
import PsmLogoVertical from "../../assets/psm-logo-vertical.png"

const BottomCarouselNews = [
  {
    id: 162,
    date: "08.22.2023",
    title: "PSM Ekim 2024",
    image: Ekim2024Cover,
    file: "https://psmdergi.com/pdf/sayi162.pdf",
  },
  {
    id: 161,
    date: "08.22.2023",
    title: "PSM Eylül 2024",
    image: "https://www.psmmag.com/wp-content/uploads/2024/09/Adsiz-tasarim-1-300x229.png",
    file: "https://psmdergi.com/pdf/sayi161.pdf",
  },
  {
    id: 160,
    date: "08.22.2023",
    title: "PSM Ağustos 2024",
    image: "https://www.psmmag.com/wp-content/uploads/2024/07/IMG_5425-300x229.jpeg",
    file: "https://psmdergi.com/pdf/sayi160.pdf",
  },
  {
    id: 159,
    date: "08.22.2023",
    title: "PSM Temmuz 2024",
    image: "https://www.psmmag.com/wp-content/uploads/2024/07/IMG_5430-300x229.jpeg",
    file: "https://psmdergi.com/pdf/sayi159.pdf",
  },
  {
    id: 158,
    date: "08.22.2023",
    title: "PSM Haziran 2024",
    image: "https://www.psmmag.com/wp-content/uploads/2024/05/IMG_5431-300x229.jpeg",
    file: "https://psmdergi.com/pdf/sayi158.pdf",
  },
  {
    id: 157,
    date: "08.23.2023",
    title: "PSM Mayıs 2024",
    image: "https://www.psmmag.com/wp-content/uploads/2024/04/IMG_4255-300x229.jpeg",
    file: "https://psmdergi.com/pdf/sayi157.pdf",
  },
  {
    id: 156,
    date: "08.24.2023",
    title: "PSM Nisan 2024",
    image: "https://www.psmmag.com/wp-content/uploads/2024/03/IMG_4018-300x229.jpeg",
    file: "https://psmdergi.com/pdf/sayi156.pdf",
  },
  {
    id: 155,
    date: "08.25.2023",
    title: "PSM Mart 2024",
    image: "https://www.psmmag.com/wp-content/uploads/2024/02/psmmartdigital-300x229.png",
    file: "https://psmdergi.com/pdf/sayi155.pdf",
  },
  {
    id: 154,
    date: "08.26.2023",
    title: "PSM Şubat 2024",
    image: "https://www.psmmag.com/wp-content/uploads/2024/01/PSM-KAPAK-1-300x229.jpg",
    file: "https://psmdergi.com/pdf/sayi154.pdf",
  },
  {
    id: 153,
    date: "08.27.2023",
    title: "PSM Ocak 2024",
    image: "https://www.psmmag.com/wp-content/uploads/2024/01/PSM-Ocak-300x229.jpg",
    file: "https://psmdergi.com/pdf/sayi153.pdf",
  },
  {
    id: 152,
    title: "Diğer Sayılar",
    image: require("../../assets/psm-logo-vertical.png"),
    file: "https://psmdergi.com/",
  },
];



const handleClick = (file) => {
  window.location.href = file;
};

function BottomCarousel() {
  const [startIndex, setStartIndex] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(6);

  const [magazineList, setMagazineList] = useState([]);

  useEffect(() => {
    const fetchMagazineData = async () => {
      try {
        const response = await fetch("https://api.psmmag.com/api/magazine");
        const data = await response.json();
  
        // createdAt alanına göre sıralama
        const sortedData = data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  
        setMagazineList(sortedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  
    fetchMagazineData();
  }, []);

  useEffect(() => {

    const mediaQuery820 = window.matchMedia("(max-width: 820px)");
    const mediaQuery768 = window.matchMedia("(max-width: 768px)");
    const mediaQuery576 = window.matchMedia("(max-width: 576px)");
    const mediaQuery430 = window.matchMedia("(max-width: 430px)");

    const handleResize = () => {
      if (mediaQuery430.matches) {
        setItemsPerPage(3);
      } else if (mediaQuery576.matches) {
        setItemsPerPage(3);
      } else if (mediaQuery768.matches) {
        setItemsPerPage(5);
      } else if (mediaQuery820.matches) {
        setItemsPerPage(5);
      } else {
        setItemsPerPage(6);
      }
      setStartIndex(0);
    };


    handleResize();


    mediaQuery768.addEventListener("change", handleResize);
    mediaQuery576.addEventListener("change", handleResize);
    mediaQuery430.addEventListener("change", handleResize);


    return () => {
      mediaQuery768.removeEventListener("change", handleResize);
      mediaQuery576.removeEventListener("change", handleResize);
      mediaQuery430.removeEventListener("change", handleResize);
    };
  }, []);

  const handlePrev = () => {
    setStartIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : magazineList.length - itemsPerPage
    );
  };

  const handleNext = () => {
    setStartIndex((prevIndex) =>
      prevIndex < magazineList.length - itemsPerPage ? prevIndex + 1 : 0
    );
  };


  const visibleItems = magazineList.slice(
    startIndex,
    startIndex + itemsPerPage
  );

  

  return (
    <div className="carouselBox">
      <h1 className="carouselTitle h2 w-full">DERGİLERİMİZ</h1>
      <div className="carousel-container container">
        <button className="carousel-button prev-button" onClick={handlePrev}>
          <span class="material-symbols-outlined pt-2">
            chevron_left
          </span>
        </button>
        <div className="carousel-content container">
          {visibleItems.map((item) => (
            <div key={item.id} className="carousel-card cursor-pointer">
              <a href={item.link} target="_blank" rel="noopener noreferrer" >
                <img src={item.cover} alt="" />
                <p className="titleContent">{item.title}</p>
              </a>
            </div>
          ))}
          {/* <div  className="carousel-card cursor-pointer">
              <a href="/dergi" rel="noopener noreferrer" >
                <img src={PsmLogoVertical} alt="" />
                <p className="titleContent">Diğer Sayılar</p>
              </a>
            </div> */}
        </div>
        <button className="carousel-button next-button" onClick={handleNext}>
          <span class="material-symbols-outlined pt-2">
            chevron_right
          </span>
        </button>
      </div>
    </div>
  );
}

export default BottomCarousel;