/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "./animatedText.css";

const AnimatedText = () => {
  const [newsItems, setNewsItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const tickerRef = useRef(null);

  useEffect(() => {
    const fetchBreakingNews = async () => {
      try {
        const response = await axios.get(
          "https://api.psmmag.com/breaking-news-animated"
        );
        const filteredNews = response.data.filter((news) => !news.isDraft).slice(0, 15);
        setNewsItems(filteredNews);
        // console.log(response.data);
      } catch (error) {
        console.error("Haberler alınırken bir hata oluştu:", error);
      } finally {
        setIsLoading(false);
      }

      
    };

    fetchBreakingNews();
  }, []);

  useEffect(() => {
    if (!isLoading && tickerRef.current) {
      const tickerWidth = tickerRef.current.offsetWidth;
      const animationDuration = tickerWidth / 50; // Adjust speed as needed
      tickerRef.current.style.animationDuration = `${animationDuration}s`;
      
      // Force a reflow to restart the animation
      tickerRef.current.style.animation = 'none';
      tickerRef.current.offsetHeight; // Trigger reflow
      tickerRef.current.style.animation = null;
    }
  }, [isLoading, newsItems]);

  const handleNewsClick = async (news) => {
    try {
      await axios.put(`https://api.psmmag.com/${news._id}/increment-view`);
    } catch (error) {
      console.error("Görüntülenme sayısı artırılırken hata oluştu:", error);
    } finally {
      window.open(`/${news.slug}`, "_blank");
    }
  };

  if (isLoading) {
    return (
      <div className="run-text page">
        <div className="ticker-wrap">
          <div className="ticker-news-text">
            <span className="breaking">SON DAKİKA</span>
          </div>
          <div className="ticker">
            <div className="ticker__item">
              <a href="#">Yükleniyor...</a>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="run-text page">
      <div className="ticker-wrap">
        <div className="ticker-news-text">
          <span className="breaking">SON DAKİKA</span>
        </div>
        <div className="ticker" ref={tickerRef}>
          {newsItems.map((news) => (
            <div className="ticker__item" key={news._id}>
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  handleNewsClick(news);
                }}
              >
                {news.title}
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AnimatedText;